<template>
  <div class="setUpPage">
    <div class="navBar">
      <div class="backBtn" @click="$router.go(-1)">
        <svg-icon iconClass="leftArrow"></svg-icon>
      </div>
      <div class="navTitle">设置</div>
    </div>
    <router-link tag="div" to="basicInfo" class="itemBox">
      <div>基本信息</div>
      <svg-icon iconClass="rightArrow"></svg-icon>
    </router-link>
    <router-link tag="div" to="coupon" class="itemBox">
      <div>优惠券</div>
      <svg-icon iconClass="rightArrow"></svg-icon>
    </router-link>
    <div class="itemBox" @click="bindCode">
      <div>邀请码</div>
      <svg-icon iconClass="rightArrow"></svg-icon>
    </div>
    <div class="itemBox" @click="useRedeemCode">
      <div>兑换码</div>
      <svg-icon iconClass="rightArrow"></svg-icon>
    </div>
    <div tag="div" to="scan" class="itemBox" @click="bindAct">
      <div>账号绑定</div>
      <svg-icon iconClass="rightArrow"></svg-icon>
    </div>
    <div tag="div" to="scan" class="itemBox">
      <input type="file" @change="upload($event)" />
      <div>扫码登陆</div>
      <svg-icon iconClass="rightArrow"></svg-icon>
    </div>
    <div class="signOut" @click="$router.push('/newLogin')" v-if="!userInfo.mobile && !userInfo.email">去登陆</div>
    <van-dialog class="invitaCode" width="277" v-model="isBindCode" title="绑定邀请码" show-cancel-button
      :before-close="onBeforeClose" @close="close">
      <van-field class="codeInput" :border='true' v-model="invitationCode" placeholder="请输入邀请码" />
    </van-dialog>
    <van-dialog class="invitaCode" width="277" v-model="isRedeemCode" title="兑换码" show-cancel-button
      :before-close="redeemCodeOpt" @close="close">
      <van-field class="codeInput" :border='true' v-model="redeemCode" placeholder="请输入兑换码" />
    </van-dialog>
  </div>
</template>

<script>
import { bindInvitaCode, useRedeemCode } from "@/api/user.js";
import qrcode from "@/assets/js/llqrcode"
import { loginGuest } from "@/api/user.js";
export default {
  data() {
    return {
      isBindCode: false,
      invitationCode: "",
      isRedeemCode: false,
      redeemCode: "",
      userInfo: '',
    };
  },
  created() {
    this.userInfo = this.$store.getters.userInfo;
  },
  methods: {
    //登陆
    async login(qrurl) {
      let Uid = localStorage.getItem("Uid");
      if (!Uid) {
        this.$store.dispatch("app/GET_UID");
      }
      let ret = await this.$Api(loginGuest, {
        certificate: qrurl,
        devID: this.$store.getters.Uid,
      });
      this.$toast.clear();
      if (ret && ret.code === 200) {
        localStorage.setItem("Authorization", ret.data.token);
        // await this.$store.dispatch("user/getUserInfo");
        this.$toast("登录成功");
        let toPath = sessionStorage.getItem("toPath");
        if (toPath) {
          this.$router.push(toPath);
        } else {
          this.$router.push("/mine");
        }
      } else {
        this.$toast(ret.tip || "登录失败");
      }
    },
    // 相册扫码
    async upload(e) {
      this.$toast.loading({
        message: "登陆中...",
        duration: 0,
        forbidClick: true,
      });
      try {
        let base64 = await this.compress(e.target.files[0]);
        // eslint-disable-next-line no-undef
        qrcode.decode(base64);
        // eslint-disable-next-line no-undef
        qrcode.callback = (imgMsg) => {
          this.login(imgMsg)
        }
      } catch (e) {
        this.isLoadingShow = false
        this.$toast("二维码识别失败");
      }
    },
    // 压缩图片
    compress(file) {
      if (!file || !window.FileReader) return;
      if (/^image/.test(file.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve) => {
          reader.onloadend = function () {
            let result = reader.result;
            let img = new Image()
            img.src = result;
            img.onload = function () {
              let canvas = document.createElement('canvas');
              let ctx = canvas.getContext('2d');
              let width = img.width;
              let height = img.height;
              canvas.width = width;
              canvas.height = height;
              // 铺底色
              ctx.fillStyle = '#fff';
              ctx.fillRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(img, 0, 0, width, height);
              //进行最小压缩
              let ndata = canvas.toDataURL('image/jpeg', 0.5);
              resolve(ndata);
            }
          }
        })
      }
    },
    scanClick() { },
    // 显示绑定邀请码弹窗
    bindCode() {
      let uInfo = this.$store.getters.userInfo;
      let createdAt = new Date(uInfo.createdAt).getTime();
      let nowTime = new Date().getTime();
      // console.log(nowTime - createdAt);
      if (nowTime - createdAt > 86400000) {
        this.$toast("注册时间超过24小时，不能绑定");
        return;
      }
      if (uInfo.agentInviteCode) {
        this.$toast("您已绑定了推广码，不能多次绑定");
        return;
      }
      this.isBindCode = true;
    },
    // 显示兑换码弹窗
    useRedeemCode() {
      this.isRedeemCode = true;
    },
    // 取消绑定邀请码
    // cancelBind() {
    //     // this.invitationCode = "";
    // },
    //  确认绑定
    async onBeforeClose(action, done) {
      if (action != "confirm") {
        return done();
      }
      let req = {
        agentInviteCode: this.invitationCode,
      };
      if (!this.invitationCode) {
        this.$toast("请输入邀请码");
        return done(false);
      }
      let ret = await this.$Api(bindInvitaCode, req);
      if (ret && ret.code == 200) {
        this.$toast("绑定成功");
        return done();
      } else {
        this.$toast(ret.tip || "绑定失败");
        return done(false);
      }
    },
    // 兑换码
    async redeemCodeOpt(action, done) {
      if (action != "confirm") {
        return done();
      }
      let req = {
        actCode: this.redeemCode,
      };
      if (!this.redeemCode) {
        this.$toast("请输入兑换码");
        return done(false);
      }
      let ret = await this.$Api(useRedeemCode, req);
      if (ret && ret.code == 200) {
        this.$toast("兑换成功");
        await this.$store.dispatch("user/getUserInfo");
        return done();
      } else {
        this.$toast(ret.tip || "兑换失败");
        return done(false);
      }
    },
    close() {
      this.invitationCode = "";
      // console.log('guanbi')
    },
    // 绑定账号
    bindAct() {
      let uInfo = this.$store.getters.userInfo;
      if (uInfo.mobile || uInfo.email) {
        this.$toast('您已绑定，请勿重复绑定');
      } else {
        this.$router.push('/bindIphone');
      }

    }
  },
};
</script>

<style lang='scss' scoped>
.setUpPage {
  background: $whiteThree;
  position: relative;

  .navBar {
    display: flex;
    align-items: center;
    height: 44px;
    font-size: 18px;
    padding: 0 10px;
    background: $white1;

    .backBtn {
      margin-right: 20px;
    }
  }

  .itemBox {
    font-size: 14px;
    height: 62px;
    background: $white1;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1px;
    cursor: pointer;

    input {
      width: 100%;
      height: 40px;
      position: absolute;
      opacity: 0;
    }
  }

  .dialogCode {
    .dialogMask {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
    }

    .dialogBox {
      height: 154px;
      width: 277px;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $black;
      background: $white1;
      border-radius: 14px;
      padding: 12px 0 0;

      .dialogTitle {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 31px;
        // margin-top: 12px;
      }

      /deep/ .van-cell__value {
        border-bottom: 1px solid $whiteFive;
      }

      .btnBox {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 44px;
        border-top: 1px solid $whiteNine;

        // margin-top: 6px;
        .btn {
          flex: 2;
          text-align: center;
          font-size: 18px;
        }

        .line {
          width: 1px;
          height: 26px;
          background: $silver;
        }

        .confirm {
          color: $brick;
          font-weight: 600;
        }
      }
    }
  }

  .signOut {
    box-sizing: border-box;
    width: 343px;
    height: 36px;
    line-height: 36px;
    font-size: 18px;
    border-radius: 15px;
    text-align: center;
    color: $white1;
    background: $vermillion;
    position: absolute;
    bottom: 60px;
    left: 16px;
  }

  .invitaCode {

    // /deep/ .van-dialog__header {
    //     padding-top: 15px;
    // }
    .codeInput {
      width: 206px;
      margin: 30px auto 16px;
      border-bottom: 1px solid #ebebeb;
    }
  }
}
</style>